html{
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-wrap: break-word;
}

body {
  margin: 0;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
}
   
::-webkit-scrollbar-track {
    background-color: #222;
}
   
::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 5px;
}

div {
    box-sizing: border-box;
}

.fullSize {
    width: 100%;
    height: 100%
}