.scroll {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.legend {
    position: absolute;
    right: 2.25em;
    bottom: 2em;
    border-radius: 5px;
    border: 1px solid gray;
    background-color: darkgray;
    overflow: hidden;
    box-shadow: 0 0 25px rgb(100,100,100);
}

.legendItem {
    padding: 0.75em;
    min-width: 15em;
}

.legendCaption {
    font-size: 120%;
    padding: 0.125em;
    background-color: #03a9f4;
    color: white;
    font-weight: bold;
}

.legendClose {
    cursor: pointer;
    width: 2em;
    height: 1em;
    position: absolute;
    right: 0;
    top: 0.75em;
    text-align: center;
}

.caption {
    min-width: 1900px;
    font-size: 150%;
    font-weight: bold;
    display: flex;
    background-color: #03a9f4;
    color: white;
    padding: 0.5em;
}

.products {
    min-width: 1900px;
    min-height: 1900px;
    display: flex;
    flex-direction: row;
    text-align: center;
    vertical-align: middle;
    border-bottom: 1px solid gray;
    border-right: 1px solid gray;
}

.product {
    flex: 1;
    min-width: 0;
    min-height: 0;
    display: flex;
    flex-direction: column;
    border-top: 1px solid white;
}

.productName {
    font-size: 120%;
    font-weight: bold;
    height: 1.5em;
    display: flex;
    background-color: #03a9f4;
    color: white;
    padding: 0.25em;
}

.product:not(:first-child) .productName, .product:not(:first-child) .productInsteadOf , .product:not(:first-child) .productDescription {
    border-left: 1px solid white;
}

.productDescription {
    font-size: 90%;
    display: flex;
    height: 3.5em;
    background-color: #03a9f4;
    color: #eee;
    padding: 0.25em;
}

.productInsteadOf {
    font-size: 95%;
    display: flex;
    height: 2em;
    background-color: #03a9f4;
    color: white;
    padding: 0.25em;
}

.module {
    display: flex;
    border-top: 0.5px solid rgba(127,127,127,0.5);
    padding: 0.25em;
}

.product:not(:first-child) .module {
    border-left: 1px solid gray;
}



span {
    margin: auto;
}

.green {
    background-color: rgb(180, 255, 180);
}

.greenDark {
    background-color: rgb(130, 252, 130);
}

.blue {
    background-color: rgb(180, 234, 255);
}

.yellow {
    background-color: rgb(255, 255, 160);
}

.orange {
    background-color: rgb(255, 215, 140);
}

.red {
    background-color: rgb(255, 180, 180);
}

.white {
    background-color: rgb(230,230,230);
}